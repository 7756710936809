.text_alingn_center_para_google {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 12px;
  color: darkorange;
  margin-right: 3%;
  margin-top: 10%;
  margin-bottom: 10%;
}
