.main_div_forgetpassword {
  width: 100%;
  padding-bottom: 25%;
}

.main_div_form_forgetpassword {
  width: 100%;
  height: 650px;
  background-image: url("./../../Assets/bg_sigup_form.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transform: translateY(-12%);
}

.form_main_div_forgetpassword {
  margin-left: 17%;
  width: 66%;
  height: 400px;
  flex-shrink: 0;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
}

.padding_div_form_forgetpassword {
  padding-left: 7%;
}
.heading_in_form_forgetpassword {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  padding-top: 5%;
}
.input_style_firstname_forgetpassword {
  padding: 13px 0px 13px 20px;
  border-radius: 30px;
  border: 1px solid rgba(35, 35, 35, 0.12);
  background: #f7f4f4;
  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 90%;
  margin-top:5% !important;
  margin-bottom: 3%;
}
.input_firstname_style_forgetpassword {
  outline: none;
  background-color: #f7f4f4;
  border-color: none;
  border-style: none;
  padding-left: 5%;
  width: 85%;
  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.eye_style_forgetpassword {
  margin-left: 2%;
  width: 18px;
  height: 18px;
  margin-top: 1%;
}

.orange_button_forgetpassword {
  width: 180px;
  height: 55px;
  align-items: center;
  border-radius: 30px;
  background: #f90;
  color: #fff;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  outline: none;
  border-color: none;
  border-style: none;
  margin-top: 3%;
}
.blue_button_forgetpassword {
  width: 180px;
  height: 55px;
  align-items: center;
  border-radius: 30px;
  background: #2859c5;
  color: #fff;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  outline: none;
  border-color: none;
  border-style: none;
}

.main_div_buttons_forgetpassword {
  text-align: center;
}

.or_para_forgetpassword {
  margin-top: 2%;
  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.para_orange_forgot_your_password {
  color: #f90;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  text-decoration-line: underline;
  margin-right: 11%;
}

.accept_term_in_forgetpassword {
  color: #f00;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 0px;
  text-decoration-line: underline;
  margin-left: 12%;
  margin-top: 3%;
}

/* Hide the default checkbox */
.checkbox_style_forgetpassword {
  display: none;
}

/* Style the label to look like a checkbox */
.checkbox_style_forgetpassword + label {
  position: relative;
  padding-left: 37px; /* Adjust as needed for your design */
  cursor: pointer;

  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px;
  margin-left: 4.5%;
  margin-bottom: 5%;
}

/* Style the tick mark */
.checkbox_style_forgetpassword:checked + label:after {
  content: "\2713"; /* Unicode checkmark character */
  color: #00b5e3; /* Color for the checkmark when selected */
  position: absolute;
  left: 0;
  font-weight: bold;
  padding-left: 4px;
  padding-bottom: 2px;
}

/* Style the background when checkbox is selected */
.checkbox_style_forgetpassword:checked + label:before {
  background-color: #8fbffa; /* Background color when selected */
  border: 2px solid #2859c5; /* Border color when not selected */
  background-color: #2859c5;
}

/* Style the border when checkbox is not selected */
.checkbox_style_forgetpassword + label:before {
  content: "";
  display: inline-block;
  width: 20px; /* Adjust as needed for your design */
  height: 20px; /* Adjust as needed for your design */
  border: 1px solid #2859c5; /* Border color when not selected */
  background-color: #8fbffa; /* Background color when not selected */
  position: absolute;
  left: 0;
  border-radius: 3px;
}

/* Hide the original checkbox visually */
.checkbox_style_forgetpassword {
  opacity: 0;
  position: absolute;
}
.term_policy_para_in_forgetpassword {
  color: #6d6d6d;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-left: 5px;
}
@media only screen and (max-width: 1400px) {
  .main_div_forgetpassword {
    width: 100%;
    padding-bottom: 20%;
  }

  .main_div_form_forgetpassword {
    width: 100%;
    height: 650px;
    background-image: url("./../../Assets/bg_sigup_form.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateY(-12%);
  }

  .form_main_div_forgetpassword {
    margin-left: 17%;
    width: 66%;
    height: 400px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  }

  .padding_div_form_forgetpassword {
    padding-left: 7%;
  }
  .heading_in_form_forgetpassword {
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    padding-top: 5%;
  }
  .input_style_firstname_forgetpassword {
    padding: 13px 0px 13px 20px;
    border-radius: 30px;
    border: 1px solid rgba(35, 35, 35, 0.12);
    background: #f7f4f4;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
    margin-bottom: 3%;
  }
  .input_firstname_style_forgetpassword {
    outline: none;
    background-color: #f7f4f4;
    border-color: none;
    border-style: none;
    padding-left: 5%;
    width: 82%;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .eye_style_forgetpassword {
    margin-left: 2%;
    width: 18px;
    height: 18px;
    margin-top: 1%;
  }

  .orange_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #f90;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
    margin-top: 3%;
  }
  .blue_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #2859c5;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }

  .main_div_buttons_forgetpassword {
    text-align: center;
  }

  .or_para_forgetpassword {
    margin-top: 2%;
  }
  .para_orange_forgot_your_password {
    color: #f90;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-decoration-line: underline;
    margin-right: 11%;
  }
  .accept_term_in_forgetpassword {
    color: #f00;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 0px;
    text-decoration-line: underline;
    margin-left: 13.9%;
    margin-top: 3%;
  }

  /* Hide the default checkbox */
  .checkbox_style_forgetpassword {
    display: none;
  }

  /* Style the label to look like a checkbox */
  .checkbox_style_forgetpassword + label {
    position: relative;
    padding-left: 37px; /* Adjust as needed for your design */
    cursor: pointer;

    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-left: 5.5%;
    margin-bottom: 5%;
  }

  /* Style the tick mark */
  .checkbox_style_forgetpassword:checked + label:after {
    content: "\2713"; /* Unicode checkmark character */
    color: #00b5e3; /* Color for the checkmark when selected */
    position: absolute;
    left: 0;
    font-weight: bold;
    padding-left: 4px;
    padding-bottom: 2px;
  }

  /* Style the background when checkbox is selected */
  .checkbox_style_forgetpassword:checked + label:before {
    background-color: #8fbffa; /* Background color when selected */
    border: 2px solid #2859c5; /* Border color when not selected */
    background-color: #2859c5;
  }

  /* Style the border when checkbox is not selected */
  .checkbox_style_forgetpassword + label:before {
    content: "";
    display: inline-block;
    width: 20px; /* Adjust as needed for your design */
    height: 20px; /* Adjust as needed for your design */
    border: 1px solid #2859c5; /* Border color when not selected */
    background-color: #8fbffa; /* Background color when not selected */
    position: absolute;
    left: 0;
    border-radius: 3px;
  }

  /* Hide the original checkbox visually */
  .checkbox_style_forgetpassword {
    opacity: 0;
    position: absolute;
  }
  .term_policy_para_in_forgetpassword {
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .main_div_forgetpassword {
    width: 100%;
    padding-bottom: 20%;
  }

  .main_div_form_forgetpassword {
    width: 100%;
    height: 650px;
    background-image: url("./../../Assets/bg_sigup_form.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateY(-12%);
  }

  .form_main_div_forgetpassword {
    margin-left: 17%;
    width: 66%;
    height: 400px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  }

  .padding_div_form_forgetpassword {
    padding-left: 7%;
  }
  .heading_in_form_forgetpassword {
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    padding-top: 5%;
  }
  .input_style_firstname_forgetpassword {
    padding: 13px 0px 13px 20px;
    border-radius: 30px;
    border: 1px solid rgba(35, 35, 35, 0.12);
    background: #f7f4f4;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
    margin-bottom: 3%;
  }
  .input_firstname_style_forgetpassword {
    outline: none;
    background-color: #f7f4f4;
    border-color: none;
    border-style: none;
    padding-left: 5%;
    width: 82%;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .eye_style_forgetpassword {
    margin-left: 2%;
    width: 18px;
    height: 18px;
    margin-top: 1%;
  }

  .orange_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #f90;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }
  .blue_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #2859c5;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }

  .main_div_buttons_forgetpassword {
    text-align: center;
  }

  .or_para_forgetpassword {
    margin-top: 2%;
  }
  .para_orange_forgot_your_password {
    color: #f90;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    text-decoration-line: underline;
    margin-right: 11%;
  }
  .accept_term_in_forgetpassword {
    color: #f00;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 0px;
    text-decoration-line: underline;
    margin-left: 15.7%;
    margin-top: 3%;
  }

  /* Hide the default checkbox */
  .checkbox_style_forgetpassword {
    display: none;
  }

  /* Style the label to look like a checkbox */
  .checkbox_style_forgetpassword + label {
    position: relative;
    padding-left: 37px; /* Adjust as needed for your design */
    cursor: pointer;

    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 20px;
    margin-left: 5.9%;
    margin-bottom: 5%;
  }

  /* Style the tick mark */
  .checkbox_style_forgetpassword:checked + label:after {
    content: "\2713"; /* Unicode checkmark character */
    color: #00b5e3; /* Color for the checkmark when selected */
    position: absolute;
    left: 0;
    font-weight: bold;
    padding-left: 4px;
    padding-bottom: 2px;
  }

  /* Style the background when checkbox is selected */
  .checkbox_style_forgetpassword:checked + label:before {
    background-color: #8fbffa; /* Background color when selected */
    border: 2px solid #2859c5; /* Border color when not selected */
    background-color: #2859c5;
  }

  /* Style the border when checkbox is not selected */
  .checkbox_style_forgetpassword + label:before {
    content: "";
    display: inline-block;
    width: 20px; /* Adjust as needed for your design */
    height: 20px; /* Adjust as needed for your design */
    border: 1px solid #2859c5; /* Border color when not selected */
    background-color: #8fbffa; /* Background color when not selected */
    position: absolute;
    left: 0;
    border-radius: 3px;
  }

  /* Hide the original checkbox visually */
  .checkbox_style_forgetpassword {
    opacity: 0;
    position: absolute;
  }
  .term_policy_para_in_forgetpassword {
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-left: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .main_div_forgetpassword {
    width: 100%;
    padding-bottom: 15%;
  }

  .main_div_form_forgetpassword {
    width: 100%;
    height: 650px;
    background-image: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateY(-5%);
  }

  .form_main_div_forgetpassword {
    margin-left: 5%;
    width: 90%;
    height: 400px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  }

  .padding_div_form_forgetpassword {
    padding-left: 7%;
  }
  .heading_in_form_forgetpassword {
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    padding-top: 5%;
  }
  .input_style_firstname_forgetpassword {
    padding: 13px 0px 13px 20px;
    border-radius: 30px;
    border: 1px solid rgba(35, 35, 35, 0.12);
    background: #f7f4f4;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
    margin-bottom: 3%;
  }
  .input_firstname_style_forgetpassword {
    outline: none;
    background-color: #f7f4f4;
    border-color: none;
    border-style: none;
    padding-left: 5%;
    width: 78%;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .eye_style_forgetpassword {
    margin-left: 2%;
    width: 18px;
    height: 18px;
    margin-top: 1%;
  }

  .orange_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #f90;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }
  .blue_button_forgetpassword {
    width: 180px;
    height: 50px;
    align-items: center;
    border-radius: 30px;
    background: #2859c5;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }

  .main_div_buttons_forgetpassword {
    text-align: center;
  }

  .or_para_forgetpassword {
    margin-top: 2%;
  }
}
@media only screen and (max-width: 515px) {
  .main_div_forgetpassword {
    width: 100%;
    padding-bottom: 25%;
  }

  .main_div_form_forgetpassword {
    width: 100%;
    height: 650px;
    background-image: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateY(-5%);
  }

  .form_main_div_forgetpassword {
    margin-left: 5%;
    width: 90%;
    height: 400px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  }

  .padding_div_form_forgetpassword {
    padding-left: 7%;
  }
  .heading_in_form_forgetpassword {
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    padding-top: 5%;
  }
  .input_style_firstname_forgetpassword {
    padding: 13px 0px 13px 20px;
    border-radius: 30px;
    border: 1px solid rgba(35, 35, 35, 0.12);
    background: #f7f4f4;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
    margin-bottom: 3%;
  }
  .input_firstname_style_forgetpassword {
    outline: none;
    background-color: #f7f4f4;
    border-color: none;
    border-style: none;
    padding-left: 5%;
    width: 78%;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .eye_style_forgetpassword {
    margin-left: 2%;
    width: 18px;
    height: 18px;
    margin-top: 1%;
  }

  .orange_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #f90;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }
  .blue_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #2859c5;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }

  .main_div_buttons_forgetpassword {
    text-align: center;
  }

  .or_para_forgetpassword {
    margin-top: 2%;
  }

  .accept_term_in_forgetpassword {
    color: #f00;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 0px;
    text-decoration-line: underline;
    margin-left: 15.5%;
    margin-top: 3%;
  }

  /* Hide the default checkbox */
  .checkbox_style_forgetpassword {
    display: none;
  }

  /* Style the label to look like a checkbox */
  .checkbox_style_forgetpassword + label {
    position: relative;
    padding-left: 37px; /* Adjust as needed for your design */
    cursor: pointer;

    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 10px;
    font-style: 20px;
    font-weight: 300;
    line-height: 20px;
    margin-left: 6%;
    margin-bottom: 5%;
  }

  /* Style the tick mark */
  .checkbox_style_forgetpassword:checked + label:after {
    content: "\2713"; /* Unicode checkmark character */
    color: #00b5e3; /* Color for the checkmark when selected */
    position: absolute;
    left: 0;
    font-weight: bold;
    padding-left: 4px;
    padding-bottom: 2px;
  }

  /* Style the background when checkbox is selected */
  .checkbox_style_forgetpassword:checked + label:before {
    background-color: #8fbffa; /* Background color when selected */
    border: 2px solid #2859c5; /* Border color when not selected */
    background-color: #2859c5;
  }

  /* Style the border when checkbox is not selected */
  .checkbox_style_forgetpassword + label:before {
    content: "";
    display: inline-block;
    width: 20px; /* Adjust as needed for your design */
    height: 20px; /* Adjust as needed for your design */
    border: 1px solid #2859c5; /* Border color when not selected */
    background-color: #8fbffa; /* Background color when not selected */
    position: absolute;
    left: 0;
    border-radius: 3px;
  }

  /* Hide the original checkbox visually */
  .checkbox_style_forgetpassword {
    opacity: 0;
    position: absolute;
  }
  .term_policy_para_in_forgetpassword {
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 10px;
    font-style: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 5px;
  }
}
@media only screen and (max-width: 400px) {
  .main_div_forgetpassword {
    width: 100%;
    padding-bottom: 20%;
  }

  .main_div_form_forgetpassword {
    width: 100%;
    height: 650px;
    background-image: none;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: translateY(-3%);
  }

  .form_main_div_forgetpassword {
    margin-left: 5%;
    width: 90%;
    height: 400px;
    flex-shrink: 0;
    background: #fff;
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);
  }

  .padding_div_form_forgetpassword {
    padding-left: 7%;
  }
  .heading_in_form_forgetpassword {
    color: #232323;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 36px;
    padding-top: 5%;
  }
  .input_style_firstname_forgetpassword {
    padding: 13px 0px 13px 20px;
    border-radius: 30px;
    border: 1px solid rgba(35, 35, 35, 0.12);
    background: #f7f4f4;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 90%;
    margin-bottom: 3%;
  }
  .input_firstname_style_forgetpassword {
    outline: none;
    background-color: #f7f4f4;
    border-color: none;
    border-style: none;
    padding-left: 5%;
    width: 72%;
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .eye_style_forgetpassword {
    margin-left: 2%;
    width: 18px;
    height: 18px;
    margin-top: 1%;
  }

  .orange_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #f90;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }
  .blue_button_forgetpassword {
    width: 180px;
    height: 55px;
    align-items: center;
    border-radius: 30px;
    background: #2859c5;
    color: #fff;
    text-align: center;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    outline: none;
    border-color: none;
    border-style: none;
  }

  .main_div_buttons_forgetpassword {
    text-align: center;
  }

  .or_para_forgetpassword {
    margin-top: 2%;
  }

  .accept_term_in_forgetpassword {
    color: #f00;
    font-family: "Rubik", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 0px;
    text-decoration-line: underline;
    margin-left: 20%;
    margin-top: 3%;
  }

  /* Hide the default checkbox */
  .checkbox_style_forgetpassword {
    display: none;
  }

  /* Style the label to look like a checkbox */
  .checkbox_style_forgetpassword + label {
    position: relative;
    padding-left: 37px; /* Adjust as needed for your design */
    cursor: pointer;

    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 7px;
    font-style: 20px;
    font-weight: 300;
    line-height: 25px;
    margin-left: 8%;
    margin-bottom: 5%;
  }

  /* Style the tick mark */
  .checkbox_style_forgetpassword:checked + label:after {
    content: "\2713"; /* Unicode checkmark character */
    color: #00b5e3; /* Color for the checkmark when selected */
    position: absolute;
    left: 0;
    font-weight: bold;
    padding-left: 4px;
    padding-bottom: 2px;
  }

  /* Style the background when checkbox is selected */
  .checkbox_style_forgetpassword:checked + label:before {
    background-color: #8fbffa; /* Background color when selected */
    border: 2px solid #2859c5; /* Border color when not selected */
    background-color: #2859c5;
  }

  /* Style the border when checkbox is not selected */
  .checkbox_style_forgetpassword + label:before {
    content: "";
    display: inline-block;
    width: 20px; /* Adjust as needed for your design */
    height: 20px; /* Adjust as needed for your design */
    border: 1px solid #2859c5; /* Border color when not selected */
    background-color: #8fbffa; /* Background color when not selected */
    position: absolute;
    left: 0;
    border-radius: 3px;
  }

  /* Hide the original checkbox visually */
  .checkbox_style_forgetpassword {
    opacity: 0;
    position: absolute;
  }
  .term_policy_para_in_forgetpassword {
    color: #6d6d6d;
    font-family: "Rubik", sans-serif;
    font-size: 7px;
    font-style: 20px;
    font-weight: 600;
    line-height: 25px;
    margin-left: 3px;
  }
}
