.main_div_barchartdashboard {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  background-color: #efefef;
  padding: 0;
  margin: 0;
  padding-bottom: 2%;
}

.main_div_bar_chart {
  width: 100%;
  height: 430px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
  padding: 20px;
}

.main_div_round_graph {
  width: 100%;
  height: 430px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
  padding: 20px;
  overflow: hidden;
}
.canvasjs-chart-credit {
  display: none !important;
}

.main_div_payment_card_dashboard {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
  width: 100%;
  height: 340px;
  flex-shrink: 0;
  padding: 23px;
}

.para_in_cardpayment_dashboard {
  color: #1d4480;

  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.main_div_card_payment_grey {
  width: 86%;
  height: 156px;
  flex-shrink: 0;
  border-radius: 11.298px;
  border: 0.706px solid #bebebe;
  background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #efefef 100%);
  margin-left: 7%;
  padding: 15px;
  margin-top: 8%;
}
.card_holder_name {
  color: #1d4480;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.image_style_cardpayment {
  width: 29.658px;
  height: 18.36px;
  flex-shrink: 0;
}
.card_number_payment {
  color: #1d4480;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 5px;
  margin-top: 11px;
}
.card_number_digits {
  color: #575757;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 0px;
}

.update_button_payment_card {
  color: #fff;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  border-radius: 30px;
  background: #1d4480;
  border-style: none;
  outline: none;
  display: flex;
  width: 60%;
  margin-left: 20%;
  padding: 13px 0px;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.blue_div_below_cardpayment {
  border-radius: 10px;
  background: linear-gradient(90deg, #2c70d6 9%, #00b5e3 100%);
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
  width: 100%;
  height: 49px;
  flex-shrink: 0;
  margin-top: 39px;
}


@media only screen and (max-width: 1500px) {
    .main_div_barchartdashboard {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        background-color: #efefef;
        padding: 0;
        margin: 0;
        padding-bottom: 5%;
      }
      
      .main_div_bar_chart {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 20px;
      }
      
      .main_div_round_graph {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 20px;
        overflow: hidden;
      }
      .canvasjs-chart-credit {
        display: none !important;
      }
      
      .main_div_payment_card_dashboard {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 340px;
        flex-shrink: 0;
        padding: 23px;
      }
      
      .para_in_cardpayment_dashboard {
        color: #1d4480;
      
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .main_div_card_payment_grey {
        width: 100%;
        height: 156px;
        flex-shrink: 0;
        border-radius: 11.298px;
        border: 0.706px solid #bebebe;
        background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #efefef 100%);
        margin-left: 0%;
        padding: 15px;
        margin-top: 12%;
      }
      .card_holder_name {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      
      .image_style_cardpayment {
        width: 29.658px;
        height: 18.36px;
        flex-shrink: 0;
      }
      .card_number_payment {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 5px;
        margin-top: 11px;
      }
      .card_number_digits {
        color: #575757;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 0px;
      }
      
      .update_button_payment_card {
        color: #fff;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        border-radius: 30px;
        background: #1d4480;
        border-style: none;
        outline: none;
        display: flex;
        width: 60%;
        margin-left: 20%;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        margin-top: 5%;
      }
      
      .blue_div_below_cardpayment {
        border-radius: 10px;
        background: linear-gradient(90deg, #2c70d6 9%, #00b5e3 100%);
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 49px;
        flex-shrink: 0;
        margin-top: 39px;
      }
      
}

@media only screen and (max-width: 1200px) {
    .main_div_barchartdashboard {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        background-color: #efefef;
        padding: 0;
        margin: 0;
        padding-bottom: 5%;
      }
      
      .main_div_bar_chart {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 10px;
      }
      
      .main_div_round_graph {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 20px;
        overflow: hidden;
      }
      .canvasjs-chart-credit {
        display: none !important;
      }
      
      .main_div_payment_card_dashboard {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 340px;
        flex-shrink: 0;
        padding: 23px;
      }
      
      .para_in_cardpayment_dashboard {
        color: #1d4480;
      
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .main_div_card_payment_grey {
        width: 100%;
        height: 156px;
        flex-shrink: 0;
        border-radius: 11.298px;
        border: 0.706px solid #bebebe;
        background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #efefef 100%);
        margin-left: 0%;
        padding: 15px;
        margin-top: 20%;
      }
      .card_holder_name {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      
      .image_style_cardpayment {
        width: 29.658px;
        height: 18.36px;
        flex-shrink: 0;
      }
      .card_number_payment {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 5px;
        margin-top: 11px;
      }
      .card_number_digits {
        color: #575757;
        font-family: "Poppins", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 0px;
      }
      
      .update_button_payment_card {
        color: #fff;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        border-radius: 30px;
        background: #1d4480;
        border-style: none;
        outline: none;
        display: flex;
        width: 60%;
        margin-left: 20%;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        margin-top: 7%;
      }
      
      .blue_div_below_cardpayment {
        border-radius: 10px;
        background: linear-gradient(90deg, #2c70d6 9%, #00b5e3 100%);
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 49px;
        flex-shrink: 0;
        margin-top: 39px;
      }
      
}

@media only screen and (max-width: 1010px) {
    .main_div_barchartdashboard {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        background-color: #efefef;
        padding: 0;
        margin: 0;
        padding-bottom: 5%;
      }
      
      .main_div_bar_chart {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 10px;
      }
      
      .main_div_round_graph {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 20px;
        overflow: hidden;
      }
      .canvasjs-chart-credit {
        display: none !important;
      }
      
      .main_div_payment_card_dashboard {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 340px;
        flex-shrink: 0;
        padding: 23px;
      }
      
      .para_in_cardpayment_dashboard {
        color: #1d4480;
      
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .main_div_card_payment_grey {
        width: 100%;
        height: 156px;
        flex-shrink: 0;
        border-radius: 11.298px;
        border: 0.706px solid #bebebe;
        background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #efefef 100%);
        margin-left: 0%;
        padding: 15px;
        margin-top: 20%;
      }
      .card_holder_name {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      
      .image_style_cardpayment {
        width: 29.658px;
        height: 18.36px;
        flex-shrink: 0;
      }
      .card_number_payment {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 5px;
        margin-top: 11px;
      }
      .card_number_digits {
        color: #575757;
        font-family: "Poppins", sans-serif;
        font-size: 9px;
        font-style: normal;
        font-weight: 400;
        line-height: 0px;
      }
      
      .update_button_payment_card {
        color: #fff;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        border-radius: 30px;
        background: #1d4480;
        border-style: none;
        outline: none;
        display: flex;
        width: 60%;
        margin-left: 20%;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        margin-top: 7%;
      }
      
      .blue_div_below_cardpayment {
        border-radius: 10px;
        background: linear-gradient(90deg, #2c70d6 9%, #00b5e3 100%);
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 49px;
        flex-shrink: 0;
        margin-top: 39px;
      }
      
}

@media only screen and (max-width: 991px) {
    .main_div_barchartdashboard {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        background-color: #efefef;
        padding: 0;
        margin: 0;
        padding-bottom: 5%;
        
      }
      
      .main_div_bar_chart {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 10px;

      }
      
      .main_div_round_graph {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 20px;
        overflow: hidden;
        margin-top: 5%;
      }
      .canvasjs-chart-credit {
        display: none !important;
      }
      
      .main_div_payment_card_dashboard {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 340px;
        flex-shrink: 0;
        padding: 23px;
        margin-top: 5%;
      }
      
      .para_in_cardpayment_dashboard {
        color: #1d4480;
      
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .main_div_card_payment_grey {
        width: 100%;
        height: 156px;
        flex-shrink: 0;
        border-radius: 11.298px;
        border: 0.706px solid #bebebe;
        background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #efefef 100%);
        margin-left: 0%;
        padding: 15px;
        margin-top: 10%;
      }
      .card_holder_name {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      
      .image_style_cardpayment {
        width: 29.658px;
        height: 18.36px;
        flex-shrink: 0;
      }
      .card_number_payment {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 5px;
        margin-top: 11px;
      }
      .card_number_digits {
        color: #575757;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 0px;
      }
      
      .update_button_payment_card {
        color: #fff;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        border-radius: 30px;
        background: #1d4480;
        border-style: none;
        outline: none;
        display: flex;
        width: 60%;
        margin-left: 20%;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        margin-top: 7%;
      }
      
      .blue_div_below_cardpayment {
        border-radius: 10px;
        background: linear-gradient(90deg, #2c70d6 9%, #00b5e3 100%);
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 49px;
        flex-shrink: 0;
        margin-top: 39px;
      }
      
}

@media only screen and (max-width: 767px) {
    .main_div_barchartdashboard {
        width: 100%;
        height: auto;
        flex-shrink: 0;
        background-color: #efefef;
        padding: 0;
        margin: 0;
        padding-bottom: 5%;
        
      }
      
      .main_div_bar_chart {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 10px;

      }
      
      .main_div_round_graph {
        width: 100%;
        height: 430px;
        flex-shrink: 0;
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        padding: 20px;
        overflow: hidden;
        margin-top: 5%;
      }
      .canvasjs-chart-credit {
        display: none !important;
      }
      
      .main_div_payment_card_dashboard {
        border-radius: 10px;
        background: #fff;
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 340px;
        flex-shrink: 0;
        padding: 23px;
        margin-top: 5%;
      }
      
      .para_in_cardpayment_dashboard {
        color: #1d4480;
      
        font-family: "Poppins", sans-serif;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      .main_div_card_payment_grey {
        width: 100%;
        height: 156px;
        flex-shrink: 0;
        border-radius: 11.298px;
        border: 0.706px solid #bebebe;
        background: radial-gradient(50% 50% at 50% 50%, #fff 0%, #efefef 100%);
        margin-left: 0%;
        padding: 15px;
        margin-top: 3%;
      }
      .card_holder_name {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
      
      .image_style_cardpayment {
        width: 29.658px;
        height: 18.36px;
        flex-shrink: 0;
      }
      .card_number_payment {
        color: #1d4480;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 5px;
        margin-top: 11px;
      }
      .card_number_digits {
        color: #575757;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 0px;
      }
      
      .update_button_payment_card {
        color: #fff;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        border-radius: 30px;
        background: #1d4480;
        border-style: none;
        outline: none;
        display: flex;
        width: 60%;
        margin-left: 20%;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        margin-top: 3%;
      }
      
      .blue_div_below_cardpayment {
        border-radius: 10px;
        background: linear-gradient(90deg, #2c70d6 9%, #00b5e3 100%);
        box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
        width: 100%;
        height: 49px;
        flex-shrink: 0;
        margin-top: 39px;
      }
      
}