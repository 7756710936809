.main_div_crm_podio {
  /* background-image: url("./../../Assets/2500_bg_podio_crm.png"); */
  background-image: url("./../../Assets/2500_bg_podio_crm.svg");
  background-size: cover;
  background-size: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 775px;
  position: relative;
  overflow: hidden;
}
.main_heading_div_podio {
  width: 35%;
  position: relative;
  top: 190px;
}
.heading_real_estate {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 28px !important;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 114.286% */
}

.excellence_para {
  color: #f90;
  font-family: "Poppins", sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 66px; /* 103.125% */
  animation: scaleHeading 1s ease-out;
}

@keyframes scaleHeading {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
.Meet_para {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
  margin: 0;
  padding: 0;
}

.hr_line_orange {
  width: 400.42px;
  height: 90px;
  flex-shrink: 0;
  border-left: 3px solid #f90;
  margin-top: 20px;
}
.hr_line_para {
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}
.button_discover {
  margin-top: 10px;
  border-radius: 30px;
  display: flex;
  width: 214.59px;
  padding: 15px 41px 15px 41px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border: 1px solid #f90;
  background: #f90;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 14px !important; 
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

@media only screen and (max-width: 2250px) {
  .main_div_crm_podio {
    /* background-image: url("./../../Assets/2500_bg_podio_crm.png"); */
    background-image: url("./../../Assets/2500_bg_podio_crm.svg");
    background-size: cover;
    background-size: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 775px;
    position: relative;
  }
  .main_heading_div_podio {
    width: 45%;
    position: relative;
    top: 190px;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 66px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 400.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }
  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
  .button_discover {
    margin-top: 10px;
    border-radius: 30px;
    display: flex;
    width: 214.59px;
    padding: 15px 41px 15px 41px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 1920px) {
  .main_div_crm_podio {
    /* background-image: url("./../../Assets/1920_ng_podio_crm.png"); */
    background-image: url("./../../Assets/1920_bg_podio_crm.svg");
    background-size: cover;
    background-size: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 775px;
    position: relative;
  }

  .main_heading_div_podio {
    width: 40% !important;
    position: relative;
    top: 185px;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 33px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 60px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 60px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 400.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .button_discover {
    margin-top: 10px;
    border-radius: 30px;
    display: flex;
    width: 214.59px;
    padding: 15px 41px 15px 41px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 1800px) {
  .main_div_crm_podio {
    /* background-image: url("./../../Assets/1800_bg_podio_crm.png"); */
    background-image: url("./../../Assets/1800_bg_podio_crm.svg");
    background-size: cover;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 775px;
    position: relative;
  }

  .main_heading_div_podio {
    width: 40%;
    position: relative;
    top: 185px;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 66px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 400.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 214.59px;
    padding: 15px 41px 15px 41px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 1600px) {
  .main_div_crm_podio {
    /* background-image: url("./../../Assets/1800_bg_podio_crm.png"); */
    background-image: url("./../../Assets/1800_bg_podio_crm.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 690px !important;
    position: relative;
  }

  .main_heading_div_podio {
    width: 50%;
    position: relative;
    top: 160px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    margin-left: -40%;
    padding: 0;
  }
  .hr_line_orange {
    width: 400.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 214.59px;
    padding: 15px 41px 15px 41px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 1450px) {
  .main_div_crm_podio {
    /* background-image: url("./../../Assets/1800_bg_podio_crm.png"); */
    background-image: url("./../../Assets/1800_bg_podio_crm.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px !important;
    position: relative;
  }

  .main_heading_div_podio {
    width: 40% !important;
    position: relative;
    top: 165px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    margin-left: -40%;
    padding: 0;
  }
  .hr_line_orange {
    width: 400.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 0px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 214.59px;
    padding: 15px 41px 15px 41px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 1400px) {
  .main_div_crm_podio {
    /* background-image: url("./../../Assets/1800_bg_podio_crm.png"); */
    background-image: url("./../../Assets/1800_bg_podio_crm.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: 600px !important;
    position: relative;
  }

  .main_heading_div_podio {
    width: 40% !important;
    position: relative;
    top: 145px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 700;
    line-height:40px;
    margin-left: -40%;
    padding: 0;
  }
  .hr_line_orange {
    width: 400.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 0px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 214.59px;
    padding: 15px 41px 15px 41px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 1200px) {
  .main_div_crm_podio {
    /* background-image: url("./../../Assets/1200_bg_podio_crm.png"); */
    background-image: url("./../../Assets/1200_bg_podio_crm.svg");
    background-size: 100%;
    background-repeat: no-repeat;

    width: 100%;
    height: 790px !important;

    position: relative;
  }

  .main_heading_div_podio {
    width:70% !important;
    position: relative;
    top: 180px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 45px !important; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 48px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    margin-left: -70%;
    padding: 0;
  }
  .hr_line_orange {
    width: 400.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 214.59px;
    padding: 15px 41px 15px 41px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 991px) {
  .main_div_crm_podio {
    /* background-image: url("./../../Assets/991_bg_podio_crm.png"); */
    background-image: url("./../../Assets/991_bg_podio_crm.svg");
    background-size: 100%;
    background-repeat: no-repeat;

    width: 100%;
    height: auto;
    padding-bottom: 25%;

    position: relative;
  }

  .main_heading_div_podio {
    width: 80% !important;
    position: relative;
    top: 120px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 34px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 38px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 38px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 66px;
    margin-left: 0%;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 400.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 214.59px;
    padding: 12px 35px 12px 35px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .main_div_crm_podio {
    background-image: url("./../../Assets/768_bg_podio_crm.png");

    background-size: 100% 100%;
    background-repeat: no-repeat;

    width: 100%;
    height: auto !important;
    padding-bottom: 40% !important;
    position: relative;
  }

  .main_heading_div_podio {
    width: 80% !important;
    position: relative;
    top: 140px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 50.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 160px;
    padding: 9px 20px 9px 20px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 570px) {
  .main_div_crm_podio {
    background-image: url("./../../Assets/530_bg_podio_crm.png") !important;
    background: #1d4480;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: auto !important;
    padding-bottom: 80% !important;
    position: relative;
  }

  .main_heading_div_podio {
    width: 80% !important;
    position: relative;
    top: 170px !important;
    margin-left: 5%;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px !important; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 30.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 160px;
    padding: 7px 22px 7px 22px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 510px) {
  .main_div_crm_podio {
    background-image: url("./../../Assets/440_bg_podio_crm.png");
    background: #1d4480;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding-bottom: 60%;
    position: relative;
  }

  .main_heading_div_podio {
    width: 80% !important;
    position: relative;
    top: 180px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 23px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 26px !important; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 27px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 27px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 20.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 160px;
    padding: 7px 22px 7px 22px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 485px) {
  .main_div_crm_podio {
    background-image: url("./../../Assets/440_bg_podio_crm.png");
    background: #1d4480;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding-bottom: 60%;
    position: relative;
  }

  .main_heading_div_podio {
    width: 80% !important;
    position: relative;
    top: 170px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 26px !important; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 20.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 160px;
    padding: 7px 22px 7px 22px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 440px) {
  .main_div_crm_podio {
    background-image: url("./../../Assets/440_bg_podio_crm.png");
    background: #1d4480;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: auto !important;
    padding-bottom: 70% !important;
    position: relative;
  }

  .main_heading_div_podio {
    width: 80% !important;
    position: relative;
    top: 150px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px !important; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 22px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 20.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 160px;
    padding: 7px 22px 7px 22px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 410px) {
  .main_div_crm_podio {
    background-image: url("./../../Assets/440_bg_podio_crm.png");
    background: #1d4480;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    width: 100%;
    height: auto !important;
    padding-bottom: 60% !important;

    position: relative;
  }

  .main_heading_div_podio {
    width: 70% !important;
    position: relative;
    top: 140px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 22px !important; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 17px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 17px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 20.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 160px;
    padding: 7px 22px 7px 22px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 380px) {
  .main_div_crm_podio {
    background-image: url("./../../Assets/440_bg_podio_crm.png");
    background: #1d4480;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    width: 100%;
    height: auto;
    padding-bottom: 60%;

    position: relative;
  }

  .main_heading_div_podio {
    width: 75% !important;
    position: relative;
    top: 120px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 22px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 17px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 17px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 20.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 160px;
    padding: 7px 22px 7px 22px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
@media only screen and (max-width: 360px) {
  .main_div_crm_podio {
    background-image: url("./../../Assets/440_bg_podio_crm.png") !important;
    background: #1d4480;
    background-size: 100% 100%;
    background-repeat: no-repeat;

    width: 100%;
    height: auto;
    padding-bottom: 50% !important;

    position: relative;
  }

  .main_heading_div_podio {
    width: 75% !important;
    position: relative;
    top: 130px !important;
  }
  .heading_real_estate {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 114.286% */
  }
  .excellence_para {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 103.125% */
  }
  .Meet_para {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    margin: 0;
    padding: 0;
  }
  .hr_line_orange {
    width: 20.42px;
    height: 90px;
    flex-shrink: 0;
    border-left: 3px solid #f90;
    margin-top: 20px;
  }

  .hr_line_para {
    color: #fff;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .button_discover {
    margin-top: 5px;
    border-radius: 30px;
    display: flex;
    width: 160px;
    padding: 7px 22px 7px 22px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
  }
}
