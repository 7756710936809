.main_div_navbar {
  z-index: 1;
  position: fixed;
  top: 0;
  width: 1296px;
}
.logo_navbar_homepage_icon {
  /* content: url("./../../Assets/1.png") !important; */
}
.main_center_navbar_items {
  margin-left: 2%;
}
.nav_item_links_style {
  margin-left: 30px;
  color: #232323 !important;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}
.main_div_style_navbar_podio {
  border-radius: 0px 0px 5px 5px;
  background: #fff;
  box-shadow: 0px 5px 5px 5px rgba(0, 0, 0, 0.1);
  width: 100% !important;
  z-index: 2;

  /* box-shadow: 0px 2px 5px -2px #0000001A !important; */
}
.nav-link {
  color: #232323 !important;
  cursor: pointer;
}
.nav-link.active {
  color: #f90 !important; /* Change this to the desired color for active links */
}
.sign_up_button_style {
  display: inline-flex;
  padding: 13px 56.84px 13px 57.33px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #f90 !important;
  background: #f90;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-right: 15px !important;
}
.sign_up_button_style:focus {
  border-radius: 30px !important;
  border: 1px solid #f90 !important;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent !important;
  border: 1px solid grey !important;
  color: #fff !important;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
.navbar-toggler-icon {
  /* color: #a1a1a1 !important; */
  background-image: url("./../../Assets/collapseiconnavbar.png") !important;
  color: grey !important;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: center !important
  ;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .15);
}
@media only screen and (max-width: 1400px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 1116px;
  }
  .main_center_navbar_items {
    margin-left: 0%;
  }
  .nav_item_links_style {
    margin-left: 10px;
    color: #232323 !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
  .nav-link {
    color: #232323 !important;
  }

  .sign_up_button_style {
    display: inline-flex;
    padding: 13px 56.84px 13px 57.33px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
}

@media only screen and (max-width: 1200px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 936px;
  }
  .main_center_navbar_items {
    margin-left: 0%;
  }
  .nav_item_links_style {
    margin-left: 8px;
    color: #232323 !important;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
  .nav-link {
    color: #232323 !important;
  }
  .logo_navbar_homepage_icon {
    height: 55.144px !important;
    width: 112.621px !important;
  }
  .sign_up_button_style {
    display: inline-flex;
    padding: 9px 30.84px 9px 30.33px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
  }
}

@media only screen and (max-width: 991px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 696px;
  }
  .main_center_navbar_items {
    margin-left: 2%;
  }
  .logo_navbar_homepage_icon {
    /* content: url("./../../Assets/smallscreenlogo.png") !important; */
    /* content: url("./../../Assets/1.png") !important; */
    height: 55.144px !important;
    width: 112.621px !important;
  }
  .nav_item_links_style {
    margin-left: 10px;
    color: #232323 !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }
  .nav-link {
    color: #000000 !important;
  }
  .main_div_style_navbar_podio {
    /* background-color: transparent; */
    background: #fff;
  }
  .navbar-logo {
    content: url("./../../Assets/smallscreenlogo.png") !important;
    width: 112.621px;
    height: 55.144px;
  }
  .sign_up_button_style {
    display: inline-flex;
    padding: 13px 36.84px 13px 37.33px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    border: 1px solid #f90;
    background: #f90;
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 20px !important;
  }
}

@media only screen and (max-width: 767px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 516px;
  }
}

@media only screen and (max-width: 575px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 545px;
  }
}

@media only screen and (max-width: 567px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 96%;
  }
}

@media only screen and (max-width: 550px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 95.5%;
  }
}
@media only screen and (max-width: 500px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 95% !important;
  }
}

@media only screen and (max-width: 450px) {
  .main_div_navbar {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 94.5% !important;
  }
}
