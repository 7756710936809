.main_div_newpassword_navbar{
    width: 100%;
    height: 350px;
    background-color: grey;
    
    background-image: url("./../../Assets/bg_footer.png");
        
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-bottom: 17%;
    overflow: hidden;
}

.heading_newpassword{
    color: #FFF;
    font-family: "Poppins", sans-serif;
font-size: 40px;
font-style: normal;
font-weight: 700;
line-height: 28px; /* 120% */
margin-top: 4%;
animation: slideInFromRight 1s ease-out; /* Animation */
}

/* Keyframes for the animation */
@keyframes slideInFromRight {
  0% {
    transform: translateX(100%); /* Start off the screen */
  }
  100% {
    transform: translateX(0); /* Slide in to original position */
  }
}
.para_newpassword_white{
    color: #FFF;

font-family: "Poppins", sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 26px; /* 162.5% */
text-transform: uppercase;
}
.orange_line_newpassword{
    width: 16px;
height: 2px;
flex-shrink: 0;
background: #FFF;
margin-top: 11px;
margin-right: 5px;
margin-left: 5px;
}
.para_newpassword_orange{
    color: #F90;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    text-transform: capitalize;
}

@media only screen and (max-width: 991px) {
    .main_div_newpassword_navbar{
        width: 100%;
        height: auto;
        background-color: grey;
        padding-bottom: 10%;

    }
    
    .heading_newpassword{
        color: #FFF;
        font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 120% */
    margin-top: 4%;
    }
    .para_newpassword_white{
        color: #FFF;
    
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 162.5% */
    text-transform: uppercase;
    }
    .orange_line_newpassword{
        width: 14px;
    height: 2px;
    flex-shrink: 0;
    background: #FFF;
    margin-top: 11px;
    margin-right: 5px;
    margin-left: 5px;
    }
    .para_newpassword_orange{
        color: #F90;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px; /* 162.5% */
        text-transform: capitalize;
    }


}