/* standard plan table style starts here  */
.main_div_table_additionalservices {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  background-color: #efefef;
  padding-bottom: 1%;
  padding-top: 5%;
}
.main_div_tablestriped_additionalservices {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.price_standardplan_additional {
  font-family: "Poppins", sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff9900;
  padding-left: 80px;
  padding-top: 15px;
}
.seelctstandardplancheckboxtext{
  font-family: "Poppins", sans-serif !important;
font-size: 14px;
font-weight: 400;
line-height: 0px;
letter-spacing: 0em;
text-align: left;
color: #FFFFFF;
}
.styled-checkbox1 {
  /* Hide the default checkbox */
  box-shadow: 0px 4px 4px 0px #00000040 inset;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #d9d9d9;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  margin-left: 15px;
  border-color: #d9d9d9;
  background-color: #d9d9d9;
  position: relative; /* Ensure the ::before pseudo-element is positioned relative to the checkbox */
}

.styled-checkbox1:checked::before {
  /* Show the tick mark when the checkbox is checked */
  content: "\2713"; /* Unicode character for tick mark */
  color: #1D4480; /* Color of the tick mark */
  font-size: 12px; /* Adjust size as needed */
  line-height: 20px; /* Align with checkbox */
  text-align: center;
  font-weight: 600;
  position: absolute; /* Position the tick mark relative to the checkbox */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the tick mark precisely */
}

/* Optional: Style the checkbox when checked */
.styled-checkbox1:checked {
  background-color: #ff9900;
  border-color: #ff9900;
}


/* Label style */
.checkbox-column label {
  margin-left: 5px; /* Adjust as needed */
  vertical-align: middle; /* Align label with the checkbox */
}

.heading_table_striped_additionalservices {
  color: #1d4480;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 20px;
  padding-top: 20px;
}

thead {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background: #1d4480 !important;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 48px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background: #fff !important;
  color: #484848 !important;
  font-family: "Rubik", sans-serif !important;

  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.table-striped tbody tr:nth-of-type(even) {
  border-radius: 10px;

  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

  font-family: "Rubik", sans-serif !important;
  background: rgba(44, 112, 214, 0.2) !important;
  color: #484848 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.button_style_paid_blue_additionalservices {
  width: 74px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 43px;
  background: #1d4480;
  color: #fff;
  font-family: "Rubik", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-style: none;
}
.button_style_Pending_orange_additionalservices {
  width: 74px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 43px;
  background: #ff9900;
  color: #fff;
  font-family: "Rubik", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-style: none;
}
.button_style_denied_red_additionalservices {
  width: 74px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 43px;
  background: #f70102;
  color: #fff;
  font-family: "Rubik", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-style: none;
}
.button_style_deleteservice_additionalservices {
  border-style: none;
}
@media only screen and (max-width: 1400px) {
  .main_div_table_additionalservices {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #efefef;
    padding-bottom: 5%;
  }
  .main_div_tablestriped_additionalservices {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
    width: 100%;
    height: auto;
    flex-shrink: 0;
  }

  .heading_table_striped_additionalservices {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 20px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background: #1d4480 !important;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 48px !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background: #fff;
    color: #484848 !important;
    font-family: "Rubik", sans-serif !important;

    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    border-radius: 10px;

    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

    font-family: "Rubik", sans-serif !important;
    background: rgba(44, 112, 214, 0.2) !important;
    color: #484848 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .button_style_paid_blue_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #1d4480;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_Pending_orange_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #ff9900;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_denied_red_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #f70102;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_deleteservice_additionalservices {
    border-style: none;
  }
}
@media only screen and (max-width: 991px) {
  .main_div_table_additionalservices {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #efefef;
    padding-bottom: 5%;
  }
  .main_div_tablestriped_additionalservices {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
    width: 100%;

    flex-shrink: 0;
  }

  .heading_table_striped_additionalservices {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 20px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background: #1d4480 !important;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 48px !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background: #fff;
    color: #484848 !important;
    font-family: "Rubik", sans-serif !important;

    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    border-radius: 10px;

    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

    font-family: "Rubik", sans-serif !important;
    background: rgba(44, 112, 214, 0.2) !important;
    color: #484848 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .button_style_paid_blue_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #1d4480;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_Pending_orange_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #ff9900;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_denied_red_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #f70102;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_deleteservice_additionalservices {
    border-style: none;
  }
}

@media only screen and (max-width: 767px) {
  .main_div_table_additionalservices {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #efefef;
    padding-bottom: 5%;
  }
  .main_div_tablestriped_additionalservices {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
    width: 100%;

    flex-shrink: 0;
  }

  .heading_table_striped_additionalservices {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 20px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background: #1d4480 !important;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 48px !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background: #fff;
    color: #484848 !important;
    font-family: "Rubik", sans-serif !important;

    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    border-radius: 10px;

    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

    font-family: "Rubik", sans-serif !important;
    background: rgba(44, 112, 214, 0.2) !important;
    color: #484848 !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .button_style_paid_blue_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #1d4480;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_Pending_orange_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #ff9900;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_denied_red_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #f70102;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_deleteservice_additionalservices {
    border-style: none;
  }
}

@media only screen and (max-width: 500px) {
  .main_div_table_additionalservices {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #efefef;
    padding-bottom: 5%;
  }
  .main_div_tablestriped_additionalservices {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
    width: 100%;

    flex-shrink: 0;
  }
  .price_standardplan_additional {
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #ff9900;
    padding-left: 80px;
    padding-top: 10px;
  }
  .heading_table_striped_additionalservices {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 20px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background: #1d4480 !important;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 48px !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background: #fff;
    color: #484848 !important;
    font-family: "Rubik", sans-serif !important;

    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    border-radius: 10px;

    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

    font-family: "Rubik", sans-serif !important;
    background: rgba(44, 112, 214, 0.2) !important;
    color: #484848 !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .button_style_paid_blue_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #1d4480;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_Pending_orange_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #ff9900;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_denied_red_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #f70102;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_deleteservice_additionalservices {
    border-style: none;
  }
}
/* addditional services table style starts here  */

.main_div_table_astable {
  width: 100%;
  height: auto;
  flex-shrink: 0;
  background-color: #efefef;
  padding-bottom: 5%;
  padding-top: 5%;
}
.main_div_tablestriped_astable {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
  width: 100%;
  height: auto;
  flex-shrink: 0;
}

.price_standardplan_astable {
  font-family: "Poppins", sans-serif !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
  color: #1d4480;
  padding-right: 92px;
  padding-top: 15px;
}
.styled-checkbox {
  /* Hide the default checkbox */
  box-shadow: 0px 4px 4px 0px #00000040 inset !important;

  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #d9d9d9;
  border-radius: 50px;
  outline: none;
  cursor: pointer;
  margin-left: 0px;
  border-color: #d9d9d9;
  background-color: #d9d9d9;
  position: relative; /* Ensure the ::before pseudo-element is positioned relative to the checkbox */
}

.styled-checkbox:checked::before {
  /* Show the tick mark when the checkbox is checked */
  content: "\2713"; /* Unicode character for tick mark */
  color: #1D4480; /* Color of the tick mark */
  font-size: 12px; /* Adjust size as needed */
  line-height: 20px; /* Align with checkbox */
  text-align: center;
  font-weight: 600;
  position: absolute; /* Position the tick mark relative to the checkbox */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Center the tick mark precisely */
}

/* Optional: Style the checkbox when checked */
.styled-checkbox:checked {
  background-color: #ff9900;
  border-color: #ff9900;
}
.selectallstyle {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  margin-top: 8px;
}
.table > :not(caption) > * > * {
  border-bottom-width: 0px !important;
}
.table td,
.table th {
  border-top: 0px solid #dee2e6 !important;
}
.heading_table_striped_astable {
  color: #1d4480;
  font-family: "Poppins", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 37px;
  padding-top: 20px;
}
.totalpaystyle {
  width: 192.17px;
  height: 52px;
  top: 2264px;
  left: 1498px;
  padding: 5px, 45.17px, 5px, 19px;
  border-radius: 30px;
  background-color: #2859c5;
  color: #ffffff;
  font-family: "Fira Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  border-color: none;
  outline: none;
  border-style: none;
  margin-top: 3%;
}
.creditpaystyleicon {
  padding-right: 10px;
}
thead {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  background: #1d4480 !important;
  color: #fff;
  font-family: "Poppins", sans-serif !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  height: 48px !important;
}
.table-striped tbody tr:nth-of-type(odd) {
  background: #fff !important;
  color: #484848 !important;
  font-family: "Rubik", sans-serif !important;

  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.table-striped tbody tr:nth-of-type(even) {
  border-radius: 10px;

  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

  font-family: "Rubik", sans-serif !important;
  background: rgba(44, 112, 214, 0.2) !important;
  color: #484848 !important;
  font-size: 18px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: normal !important;
}

.button_style_paid_blue_additionalservices {
  width: 74px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 43px;
  background: #1d4480;
  color: #fff;
  font-family: "Rubik", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-style: none;
}
.button_style_Pending_orange_additionalservices {
  width: 74px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 43px;
  background: #ff9900;
  color: #fff;
  font-family: "Rubik", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-style: none;
}
.button_style_denied_red_additionalservices {
  width: 74px;
  height: 26px;
  flex-shrink: 0;
  border-radius: 43px;
  background: #f70102;
  color: #fff;
  font-family: "Rubik", sans-serif !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-style: none;
}
.button_style_deleteservice_additionalservices {
  border-style: none;
}
@media only screen and (max-width: 1400px) {
  .main_div_table_astable {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #efefef;
    padding-bottom: 5% !important;
  }
  .main_div_tablestriped_astable {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
    width: 100%;
    height: auto;
    flex-shrink: 0;
  }

  .heading_table_striped_astable {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 30px;
    padding-top: 20px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background: #1d4480 !important;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 48px !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background: #fff;
    color: #484848 !important;
    font-family: "Rubik", sans-serif !important;

    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    border-radius: 10px;

    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

    font-family: "Rubik", sans-serif !important;
    background: rgba(44, 112, 214, 0.2) !important;
    color: #484848 !important;
    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .button_style_paid_blue_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #1d4480;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_Pending_orange_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #ff9900;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_denied_red_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #f70102;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_deleteservice_additionalservices {
    border-style: none;
  }
}
@media only screen and (max-width: 991px) {
  .main_div_table_astable {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #efefef;
    padding-bottom: 7%;
  }
  .main_div_tablestriped_astable {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
    width: 100%;

    flex-shrink: 0;
  }

  .heading_table_striped_astable {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 25px;
    padding-top: 20px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background: #1d4480 !important;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 48px !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background: #fff;
    color: #484848 !important;
    font-family: "Rubik", sans-serif !important;

    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    border-radius: 10px;

    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

    font-family: "Rubik", sans-serif !important;
    background: rgba(44, 112, 214, 0.2) !important;
    color: #484848 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .button_style_paid_blue_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #1d4480;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_Pending_orange_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #ff9900;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_denied_red_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #f70102;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_deleteservice_additionalservices {
    border-style: none;
  }
}

@media only screen and (max-width: 767px) {
  .main_div_table_astable {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #efefef;
    padding-bottom: 15%;
  }
  .main_div_tablestriped_astable {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
    width: 100%;

    flex-shrink: 0;
  }
  .price_standardplan_astable {
    font-family: "Poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: #1d4480;
    padding-right: 42px;
    padding-top: 11px;
  }
  .heading_table_striped_astable {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 20px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background: #1d4480 !important;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 48px !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background: #fff;
    color: #484848 !important;
    font-family: "Rubik", sans-serif !important;

    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    border-radius: 10px;

    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

    font-family: "Rubik", sans-serif !important;
    background: rgba(44, 112, 214, 0.2) !important;
    color: #484848 !important;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .button_style_paid_blue_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #1d4480;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_Pending_orange_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #ff9900;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_denied_red_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #f70102;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_deleteservice_additionalservices {
    border-style: none;
  }

  .selectallstyle {
    font-family: "Poppins", sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-top: 8px;
  }
}

@media only screen and (max-width: 500px) {
  .main_div_table_astable {
    width: 100%;
    height: auto;
    flex-shrink: 0;
    background-color: #efefef;
    padding-bottom: 15%;
  }
  .main_div_tablestriped_astable {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);
    width: 100%;

    flex-shrink: 0;
  }

  .heading_table_striped_astable {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 20px;
  }

  thead {
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    background: #1d4480 !important;
    color: #fff;
    font-family: "Poppins", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    height: 48px !important;
  }
  .table-striped tbody tr:nth-of-type(odd) {
    background: #fff;
    color: #484848 !important;
    font-family: "Rubik", sans-serif !important;

    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .table-striped tbody tr:nth-of-type(even) {
    border-radius: 10px;

    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.02);

    font-family: "Rubik", sans-serif !important;
    background: rgba(44, 112, 214, 0.2) !important;
    color: #484848 !important;
    font-size: 10px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
  }

  .button_style_paid_blue_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #1d4480;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_Pending_orange_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #ff9900;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_denied_red_additionalservices {
    width: 74px;
    height: 26px;
    flex-shrink: 0;
    border-radius: 43px;
    background: #f70102;
    color: #fff;
    font-family: "Rubik", sans-serif !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-style: none;
  }
  .button_style_deleteservice_additionalservices {
    border-style: none;
  }

  .selectallstyle {
    font-family: "Poppins", sans-serif;
    font-size: 10px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #ffffff;
    margin-top: 8px;
  }
  .table > :not(caption) > * > * {
    border-bottom-width: 0px !important;
  }
  .table td,
  .table th {
    border-top: 0px solid #dee2e6 !important;
  }
  .heading_table_striped_astable {
    color: #1d4480;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding-left: 20px;
    padding-top: 20px;
  }
  .totalpaystyle {
    width: 152.17px;
    height: 52px;
    top: 2264px;
    left: 1498px;
    padding: 5px, 45.17px, 5px, 19px;
    border-radius: 30px;
    background-color: #2859c5;
    color: #ffffff;
    font-family: "Fira Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    border-color: none;
    outline: none;
    border-style: none;
    margin-top: 3%;
  }
  .creditpaystyleicon {
    padding-right: 10px;
    width: 42px;
    height: 32px;
  }
}
