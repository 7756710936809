.main_div_profile_edit {
  width: 100%;
  height: auto;
  border-radius: 3px 3px 3px 3px;
  background: #f7f4f4;
  margin-top: 70px;
  margin-bottom: 330px;
  padding-top: 20px;
  padding-bottom: 50px;
}
.edit_para_edit_ptofile {
  color: #232323;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
}
.profile_para_edit_ptofile {
  color: #ff9900;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 43.2px;
  text-align: center;
  margin-left: 6px;
}
.input_style_name_edit {
  width: 45%;
  height: 50px;
  border-radius: 30px 30px 30px 30px;
  border: 1px 1px 1px 1px;
  opacity: 0px;
  background: #ffffff;
  border: 1px solid #2323231f;
  margin-top: 12px;
}
.icon_input_name {
  margin-top: 11px;
  margin-left: 20px;
}
.input_style_firstname_edit {
  border-color: none;
  outline: none;
  border-style: none;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
  margin-top: 10px;
  color: #6d6d6d;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: left;
}
.input_style_name_edit_address {
  width: 45%;
  height: 135px;
  border-radius: 30px 30px 30px 30px;
  border: 1px 1px 1px 1px;
  opacity: 0px;
  background: #ffffff;
  border: 1px solid #2323231f;
  margin-top: 12px;
  overflow: hidden;
}
.input_style__edit_address {
  border-color: none;
  outline: none;
  border-style: none;
  margin-left: 20px;
  margin-right: 20px;
  width: 100%;
  height: 100px;
  margin-top: 10px;
  color: #6d6d6d;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.96px;
  text-align: left;
}

.blue_para_edit_profile {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 14.96px;
  text-align: center;
  color: #2859c5;
  text-decoration: underline;
}

.grey_para_edit_profile {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.96px;
  text-align: center;
  color: #6d6d6d;
  margin-top: 35px;
}
.btn_submit_edit_profile {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
  width: 138px;
  height: 50px;
  border-radius: 30px 30px 30px 30px;
  background: #2859c5;
  outline: none;
  border-color: none;
  border-style: none;
  text-align: center;
  margin-top: 20px;
}


@media only screen and (max-width: 991px) {
    .main_div_profile_edit {
        width: 100%;
        height: auto;
        border-radius: 3px 3px 3px 3px;
        background: #f7f4f4;
        margin-top: 70px;
        margin-bottom: 330px;
        padding-top: 20px;
        padding-bottom: 50px;
      }
      .edit_para_edit_ptofile {
        color: #232323;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: center;
      }
      .profile_para_edit_ptofile {
        color: #ff9900;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: center;
        margin-left: 6px;
      }
      .input_style_name_edit {
        width: 96%;
        height: 50px;
        border-radius: 30px 30px 30px 30px;
        border: 1px 1px 1px 1px;
        opacity: 0px;
        background: #ffffff;
        border: 1px solid #2323231f;
        margin-top: 12px;
      }
      .icon_input_name {
        margin-top: 11px;
        margin-left: 20px;
      }
      .input_style_firstname_edit {
        border-color: none;
        outline: none;
        border-style: none;
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;
        margin-top: 10px;
        color: #6d6d6d;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
      }
      .input_style_name_edit_address {
        width: 96%;
        height: 135px;
        border-radius: 30px 30px 30px 30px;
        border: 1px 1px 1px 1px;
        opacity: 0px;
        background: #ffffff;
        border: 1px solid #2323231f;
        margin-top: 12px;
        overflow: hidden;
      }
      .input_style__edit_address {
        border-color: none;
        outline: none;
        border-style: none;
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        color: #6d6d6d;
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
      }
      
      .blue_para_edit_profile {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 14.96px;
        text-align: center;
        color: #2859c5;
        text-decoration: underline;
      }
      
      .grey_para_edit_profile {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.96px;
        text-align: center;
        color: #6d6d6d;
        margin-top: 35px;
      }
      .btn_submit_edit_profile {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
        width: 138px;
        height: 50px;
        border-radius: 30px 30px 30px 30px;
        background: #2859c5;
        outline: none;
        border-color: none;
        border-style: none;
        text-align: center;
        margin-top: 20px;
      }
}


@media only screen and (max-width: 500px) {
    .main_div_profile_edit {
        width: 100%;
        height: auto;
        border-radius: 3px 3px 3px 3px;
        background: #f7f4f4;
        margin-top: 70px;
        margin-bottom: 330px;
        padding-top: 20px;
        padding-bottom: 50px;
      }
      .edit_para_edit_ptofile {
        color: #232323;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: center;
      }
      .profile_para_edit_ptofile {
        color: #ff9900;
        font-family: "Poppins", sans-serif;
        font-size: 14px;
        font-weight: 700;
        line-height: 43.2px;
        text-align: center;
        margin-left: 6px;
      }
      .input_style_name_edit {
        width: 96%;
        height: 50px;
        border-radius: 30px 30px 30px 30px;
        border: 1px 1px 1px 1px;
        opacity: 0px;
        background: #ffffff;
        border: 1px solid #2323231f;
        margin-top: 12px;
      }
      .icon_input_name {
        margin-top: 11px;
        margin-left: 20px;
      }
      .input_style_firstname_edit {
        border-color: none;
        outline: none;
        border-style: none;
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;
        margin-top: 10px;
        color: #6d6d6d;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
      }
      .input_style_name_edit_address {
        width: 96%;
        height: 135px;
        border-radius: 30px 30px 30px 30px;
        border: 1px 1px 1px 1px;
        opacity: 0px;
        background: #ffffff;
        border: 1px solid #2323231f;
        margin-top: 12px;
        overflow: hidden;
      }
      .input_style__edit_address {
        border-color: none;
        outline: none;
        border-style: none;
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;
        height: 100px;
        margin-top: 10px;
        color: #6d6d6d;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 18.96px;
        text-align: left;
      }
      
      .blue_para_edit_profile {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 14.96px;
        text-align: center;
        color: #2859c5;
        text-decoration: underline;
        padding-left: 10px;
        padding-right: 10px;
      }
      
      .grey_para_edit_profile {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 1.96px;
        text-align: center;
        color: #6d6d6d;
        margin-top: 35px;
        padding-left: 10px;
        padding-right: 10px;
      }
      .btn_submit_edit_profile {
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        color: #ffffff;
        width: 138px;
        height: 50px;
        border-radius: 30px 30px 30px 30px;
        background: #2859c5;
        outline: none;
        border-color: none;
        border-style: none;
        text-align: center;
        margin-top: 20px;
      }
}