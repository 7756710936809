.main_div_automated_running {
  width: 100% !important;
  height: 191px;
  background-color: #1d4480;
}
.main_div_automated_para_wrap {
  margin-top: 17%;
}
.bold_number_para_automated {
  color: #fff !important;
  font-family: "Poppins", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px; /* 120% */
  margin-left: 25%;
}

.automated_running_para {
  color: #9d9ea6;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
  margin-left: 25%;
}

.plus_sign_style {
  width: 28.399px;
  height: 26px;
  margin-top: 11px;
  margin-left: 15px;
}

@media only screen and (max-width: 1100px) {
  .main_div_automated_running {
    width: 100% !important;
    height: 191px;
    background-color: #1d4480;
  }
  .main_div_automated_para_wrap {
    margin-top: 17%;
  }
  .bold_number_para_automated {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 34px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 120% */
    margin-left: 25%;
  }

  .automated_running_para {
    color: #9d9ea6;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    margin-left: 25%;
  }

  .plus_sign_style {
    width: 24.399px;
    height: 22px;
    margin-top: 11px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 1000px) {
  .main_div_automated_running {
    width: 100% !important;
    height: auto;
    background-color: #1d4480;
  }
  .main_div_automated_para_wrap {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .bold_number_para_automated {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 120% */
    margin-left: 25%;
  }

  .automated_running_para {
    color: #9d9ea6;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    margin-left: 25%;
  }

  .plus_sign_style {
    width: 22.399px;
    height: 20px;
    margin-top: 13px;
    margin-left: 15px;
  }
}
@media only screen and (max-width: 768px) {
    .main_div_automated_running {
      width: 100% !important;
      height: auto;
      background-color: #1d4480;
    }
    .main_div_automated_para_wrap {
      margin-top: 5%;
      padding-bottom: 5%;
    }
    .bold_number_para_automated {
      color: #fff;
      font-family: "Poppins", sans-serif;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 48px; /* 120% */
      margin-left: 15%;
    }
  
    .automated_running_para {
      color: #9d9ea6;
      font-family: "Rubik", sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px; /* 162.5% */
      margin-left: 15%;
    }
  
    .plus_sign_style {
      width: 18.399px;
      height: 16px;
      margin-top: 15px;
      margin-left: 15px;
    }
  }
@media only screen and (max-width: 600px) {
    .main_div_automated_running {
        width: 100% !important;
        height: auto;
        background-color: #1d4480;
      }
      .main_div_automated_para_wrap {
        margin-top: 5%;
        margin-bottom: 5%;
      }
      .bold_number_para_automated {
        color: #fff;
        font-family: "Poppins", sans-serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px; /* 120% */
        margin-left: 15%;
      }
    
      .automated_running_para {
        color: #9d9ea6;
        font-family: "Rubik", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px; /* 162.5% */
        margin-left: 15%;
      }

  .plus_sign_style {
    width: 18.399px;
    height: 16px;
    margin-top: 15px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 425px) {
  .main_div_automated_running {
    width: 100% !important;
    height: auto;
    background-color: #1d4480;
  }
  .main_div_automated_para_wrap {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .bold_number_para_automated {
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 120% */
    margin-left: 15%;
  }

  .automated_running_para {
    color: #9d9ea6;
    font-family: "Rubik", sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
    margin-left: 15%;
  }

  .plus_sign_style {
    width: 15.399px;
    height: 13px;
    margin-top: 15px;
    margin-left: 5px;
  }
}
