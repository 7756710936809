.main_div_otherservicesadds {
  background-image: url("./../../Assets/form_bg_homepage.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: auto;
  padding-bottom: 5%;
  overflow: hidden;
}
.our_pricing_center_otherservicesadds {
  padding-top: 2%;
}

.hr_line_pricng_orange_otherservicesadds {
  width: 41px;
  height: 2px;
  flex-shrink: 0;
  background: #f90;
  margin-top: 12px;
  margin-left: 5px;
  margin-right: 5px;
}
.our_pricing_text_otherservicesadds {
  display: flex;
  width: 99.553px;
  height: 25px;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #f90;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.6px; /* 120% */
  text-transform: capitalize;
}
.choose_for_best_text_black_otherservicesadds {
  color: #232323;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 43.2px; /* 120% */
}
.pricing_plans_orange_otherservicesadds {
  color: #f90;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 43.2px;
  margin-left: 10px;
  margin-bottom: 2%;
}

.div_card_rapidskip {
  width: 100%;
  height: 484px;
  background: linear-gradient(180deg, #00ffd0 0%, #008970 100%);
  box-shadow: 0px 10px 11px 0px #0000001a;
}

.rapidskipiconstyle {
  margin-top: 6%;
  margin-left: 8%;
}

.rapidskippicstyle {
  margin-top: 2%;
}
.main_div_per_record {
  width: 90%;
  height: auto;
}
.para_in_rapid_skip {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: justify;
}
.dollar_price_rapidskip {
  font-family: "Akshar", sans-serif;
  font-size: 63px;
  font-weight: 600;
  line-height: 69px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 10%;
}
.record_rapidskip {
  font-family: "Akshar", sans-serif;
  font-size: 25px;
  font-weight: 500;
  line-height: 69px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin-top: 14%;
}
.minorder_rapidskip {
  font-family: "Akshar", sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: right;
  margin: 0;
  padding: 0;
  color: #ffffff;
}
.main_div_button_discover {
  text-align: right;
}
.discovermore_rapidskip {
  width: 214.59px;
  height: 60px;
  top: 4086px;
  left: 822px;
  padding: 17px, 40.64px, 11px, 41px;
  border-radius: 30px;
  border: 1px;
  background: #ff9900;
  border: 1px solid #ff9900;
  font-family: "Fira Sans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  text-align: center;
  margin-top: 5%;
}
@media only screen and (max-width: 1400px) {
    .main_div_otherservicesadds {
        background-image: url("./../../Assets/form_bg_homepage.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: auto;
        padding-bottom: 5%;
        overflow: hidden;
      }
      .our_pricing_center_otherservicesadds {
        padding-top: 1%;
      }
      
      .hr_line_pricng_orange_otherservicesadds {
        width: 41px;
        height: 2px;
        flex-shrink: 0;
        background: #f90;
        margin-top: 12px;
        margin-left: 5px;
        margin-right: 5px;
      }
      .our_pricing_text_otherservicesadds {
        display: flex;
        width: 99.553px;
        height: 25px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
        color: #f90;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 21.6px; /* 120% */
        text-transform: capitalize;
      }
      .choose_for_best_text_black_otherservicesadds {
        color: #232323;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 43.2px; /* 120% */
      }
      .pricing_plans_orange_otherservicesadds {
        color: #f90;
        font-family: "Poppins", sans-serif;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 43.2px;
        margin-left: 10px;
        margin-bottom: 2%;
      }
      
      .div_card_rapidskip {
        width: 100%;
        height: 484px;
        background: linear-gradient(180deg, #00ffd0 0%, #008970 100%);
        box-shadow: 0px 10px 11px 0px #0000001a;
      }
      
      .rapidskipiconstyle {
        margin-top: 8%;
        margin-left: 0%;
      }
      
      .rapidskippicstyle {
        margin-top: 2%;
      }
      .main_div_per_record {
        width: 90%;
        height: auto;
      }
      .para_in_rapid_skip {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 25px;
        letter-spacing: 0em;
        text-align: justify;
      }
      .dollar_price_rapidskip {
        font-family: "Akshar", sans-serif;
        font-size: 63px;
        font-weight: 600;
        line-height: 69px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
        margin-top: 10%;
      }
      .record_rapidskip {
        font-family: "Akshar", sans-serif;
        font-size: 25px;
        font-weight: 500;
        line-height: 69px;
        letter-spacing: 0em;
        text-align: center;
        color: #ffffff;
        margin-top: 14%;
      }
      .minorder_rapidskip {
        font-family: "Akshar", sans-serif;
        font-size: 19px;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: right;
        margin: 0;
        padding: 0;
        color: #ffffff;
      }
      .main_div_button_discover {
        text-align: right;
      }
      .discovermore_rapidskip {
        width: 214.59px;
        height: 60px;
        top: 4086px;
        left: 822px;
        padding: 17px, 40.64px, 11px, 41px;
        border-radius: 30px;
        border: 1px;
        background: #ff9900;
        border: 1px solid #ff9900;
        font-family: "Fira Sans", sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        color: #ffffff;
        text-align: center;
        margin-top: 5%;
      }


}