.main_div_serviceinclude {
  background-image: url("./../../Assets/form_bg_homepage.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 731px;
}

.serviceinclude_heading {
  color: #f90;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 43.2px; /* 120% */
  padding-top: 5%;
}
.para_serviceinclude_bewlo_h {
  color: #686868;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 162.5% */
}

.goodtick_style_services_include {
  margin-right: 17px;
  width: 15.375px;
  height: 15px;
  margin-left: 10px;
}

.more_serviceinclude_style {
  margin-left: 17px;
  width: 15.375px;
  height: 15px;
}

.para_in_serviceinclude {
  color: #686868;
  font-family: "Rubik", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 162.5% */
}
.merge_icons_style {
  width: 100%;
  height: 526px;
}

@media only screen and (max-width: 991px) {
  .main_div_serviceinclude {
    background-image: url("./../../Assets/form_bg_homepage.png");

    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    height: auto;
    padding-bottom: 3%;
  }

  .serviceinclude_heading {
    color: #f90;
    font-family: "Poppins", sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 43.2px; /* 120% */
    padding-top: 5%;
  }
  .para_serviceinclude_bewlo_h {
    color: #686868;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; /* 162.5% */
  }

  .goodtick_style_services_include {
    margin-right: 17px;
    width: 15.375px;
    height: 15px;
    margin-left: 10px;
  }

  .more_serviceinclude_style {
    margin-left: 17px;
    width: 15.375px;
    height: 15px;
  }

  .para_in_serviceinclude {
    color: #686868;
    font-family: "Rubik", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 162.5% */
  }
  .merge_icons_style {
    width: 100%;
    height: 526px;
  }
}
